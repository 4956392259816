import React, {forwardRef, useContext, useEffect, useState} from 'react';
import './Step4.scss';
import {observer} from 'mobx-react';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import moment from 'moment';
import {i18nContext} from '../../../../../../components/i18n/I18nProvider';
import {RealEstate, realEstateStoreContext} from '../../../../../../store/components/RealEstateStore';
import Button from '../../../../../../components/ui/Button/Button';
import FieldText from '../../../../../../components/ui/FieldText/FieldText';
import Datepicker from '../../../../../../components/ui/Datepicker/Datepicker';
import FieldRegexp from '../../../../../../components/ui/FieldRegexp/FieldRegexp';
import Textarea from '../../../../../../components/ui/Textarea/Textarea';
import FieldPhone from '../../../../../../components/ui/FieldPhone/FieldPhone';

type Step4Props = {
    onNextStep: () => void;
    onSubmitSuccess: (isSuccess: boolean) => void;
}

const Step4: React.FC<Step4Props> = observer((props) => {
    const {i18n} = useContext(i18nContext);
    const methods = useForm<Inputs>({mode: 'onChange'});
    const {handleSubmit} = methods;
    const realEstateStoreCtx = useContext(realEstateStoreContext);
    const [requestDate, setRequestDate] = useState<Date>(realEstateStoreCtx.realEstate.requestDate ? new Date(realEstateStoreCtx.realEstate.requestDate) : new Date());
    const [requestTimeStart, setRequestTimeStart] = useState<Date | null>(realEstateStoreCtx.realEstate.requestTimeStart ? new Date(realEstateStoreCtx.realEstate.requestTimeStart) : null);
    const [requestTimeEnd, setRequestTimeEnd] = useState<Date | null>(realEstateStoreCtx.realEstate.requestTimeEnd ? new Date(realEstateStoreCtx.realEstate.requestTimeEnd) : null);
    const [isRequest, setRequest] = useState(false);
    useEffect(() => {
        if (!realEstateStoreCtx.realEstate.requestDate) {
            handleChangeRequestDate(new Date());
        }
    }, []);
    useEffect(() => {
        realEstateStoreCtx.mergeRealState({requestDate: moment(requestDate ?? new Date()).format()});
    }, [requestDate]);
    useEffect(() => {
        if (requestTimeStart) {
            realEstateStoreCtx.mergeRealState({requestTimeStart: moment(requestTimeStart).format()});
        }
    }, [requestTimeStart]);
    useEffect(() => {
        if (requestTimeEnd) {
            realEstateStoreCtx.mergeRealState({requestTimeEnd: moment(requestTimeEnd).format()});
        }
    }, [requestTimeEnd]);
    const handleSelectRequestType = (type: string) => {
        realEstateStoreCtx.mergeRealState({requestType: type, email: '', phone: '', requestDate: '', requestTimeStart: '', requestTimeEnd: ''});
        if (type === 'call') {
            handleChangeRequestDate(new Date());
        }
    }

    const handleChangeFieldText = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName = event.target.name
        realEstateStoreCtx.mergeRealState({[fieldName]: event.target.value});
        if (fieldName === 'phone') {
            methods.setValue('phone2', event.target.value);
            methods.trigger('phone2').then(() => {});
        }
    }

    const handleChangeRequestDate = (date: Date) => {
        setRequestDate(date);
    }

    const handleChangeRequestTimeStart = (time1: Date) => {
        setRequestTimeStart(time1);
    }

    const handleChangeRequestTimeEnd = (time2: Date) => {
        setRequestTimeEnd(time2);
    }

    const onSubmit: SubmitHandler<Inputs> = () => {
        const data: RealEstate = JSON.parse(JSON.stringify(realEstateStoreCtx.getRealEstate()));
        let key: string;
        for (key in data) {
            // @ts-ignore
            if (key === 'street' && data[key] === '') {
                data[key] = data.presentationAddress;
            }
            // @ts-ignore
            if (data[key] === '') {
                // @ts-ignore
                data[key] = null;
            }
            // @ts-ignore
            if (key === 'phone' && data[key] === null) {
                // @ts-ignore
                data.phoneCode = null;
            }
        }
        setRequest(true);
        realEstateStoreCtx.createRealEstate(data)
            .then((result) => {
                if (result) {
                    props.onSubmitSuccess(true);
                }
            }).finally(() => setRequest(false))
    }

    // @ts-ignore
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        // @ts-ignore
        <button type="button" className="field-text" onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    const requestType = realEstateStoreCtx.realEstate.requestType;
    return (<FormProvider {...methods} >
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-content">
            <div className="row center-xs center-sm center-md center-lg center-xl">
                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 step-description">
                    <h3>{i18n.t('sellModal.step4.title')}</h3>
                </div>
            </div>
            <div className="row center-xs center-sm center-md center-lg center-xl">
                <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                    <Button
                        className={`button button--small button--circle ${requestType !== 'mail' ? 'button--light' : ''}`}
                        onClick={() => handleSelectRequestType('mail')}>
                        <i className="icon-main"/>
                        {i18n.t('sellModal.step4.buttonMail')}
                    </Button>
                </div>
                <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                    <Button
                        className={`button button--small button--circle ${requestType !== 'call' ? 'button--light' : ''}`}
                        onClick={() => handleSelectRequestType('call')}>
                        <i className="icon-phone"/>
                        {i18n.t('sellModal.step4.buttonPhone')}
                    </Button>
                </div>
            </div>
            {requestType && <>
                <div className="row center-xs center-sm center-md center-lg center-xl">
                    <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                        <FieldText
                            placeholder={i18n.t('sellModal.step4.fullNamePlaceholder')}
                            value={realEstateStoreCtx.realEstate.fullName ?? ''}
                            registerProps={{name: 'fullName', options: {required: true, onChange: handleChangeFieldText}}}
                        />
                    </div>
                    {requestType === 'mail'
                        ?
                        <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <FieldText
                                placeholder={i18n.t('sellModal.step4.emailPlaceholder')}
                                value={realEstateStoreCtx.realEstate.email ?? ''}
                                registerProps={{name: 'email', options: {required: true, pattern: /^\S+@\S+$/i, onChange: handleChangeFieldText}}}
                            />
                        </div>
                        :
                        <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <Datepicker
                                className={`field-text ${methods && methods.formState && methods.formState.errors && methods.formState.errors.requestDate ? 'field-text-invalid' : ''}`}
                                placeholderText="01/06/2024"
                                dateFormat={'dd/MM/YYYY'}
                                selected={requestDate}
                                onChange={handleChangeRequestDate}
                                // @ts-ignore
                                minDate={moment().subtract(1, 'day')._d}
                                name={'requestDate'}
                                customInput={<ExampleCustomInput />}
                            />
                        </div>
                    }
                </div>
                {requestType === 'call' && <>
                    <div className="row center-xs center-sm center-md center-lg center-xl">
                        <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5">
                            <input type="hidden" {...methods.register('phone2', {required: realEstateStoreCtx.realEstate.requestType === 'call', onChange: handleChangeFieldText})}/>
                            <FieldPhone
                                type="tel"
                                placeholder={'_________'}
                                className={`field-phone ${methods && methods.formState.errors && methods.formState.errors.phone2 ? 'field-phone--invalid' : ''}`}
                                value={realEstateStoreCtx.realEstate.phone ?? ''}
                                onChange={handleChangeFieldText}
                                name={'phone'}
                                maxLength={9}
                                phoneCode={realEstateStoreCtx.realEstate.phoneCode ?? ''}
                                onChangePhoneCode={(phoneCode) => realEstateStoreCtx.mergeRealState({phoneCode})}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2 col-50">
                            <Datepicker
                                placeholderText="12:00"
                                selected={requestTimeStart}
                                onChange={handleChangeRequestTimeStart}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={60}
                                timeLable={i18n.t('sellModal.step4.fromPlaceholder')}
                                dateFormat="HH:mm"
                                timeCaption={i18n.t('sellModal.step4.fromPlaceholder')}
                                timeFormat="HH:mm"/>
                        </div>
                        <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2 col-50">
                            <Datepicker
                                placeholderText="14:00"
                                selected={requestTimeEnd}
                                filterTime={(time: any) => {
                                    if (!requestTimeStart) {
                                        return true;
                                    }
                                    return moment(time).format('HH:mm') > moment(requestTimeStart).format('HH:mm');
                                }}
                                onChange={handleChangeRequestTimeEnd}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={60}
                                timeLable={i18n.t('sellModal.step4.toPlaceholder')}
                                dateFormat="HH:mm"
                                timeCaption={i18n.t('sellModal.step4.toPlaceholder')}
                                timeFormat="HH:mm"/>
                        </div>
                    </div>
                </>}
                <div className="row center-xs center-sm center-md center-lg center-xl">
                    <div className="col-xs-12 col-sm-10 col-md-10 col-lg-10">
                        <Textarea
                            name="description"
                            value={realEstateStoreCtx.realEstate.description}
                            onChange={handleChangeFieldText}
                            placeholder={i18n.t('sellModal.step4.descriptionPlaceholder')}
                        />
                    </div>
                </div>
            </>}
        </div>
        {requestType && <div className="modal-actions">
            <div className="row center-xs">
                <div className="col-xs-12 col-sm-8 col-md-5 col-lg-5 ta-center">
                    <Button disabled={isRequest} className="button" type="submit">{i18n.t('sellModal.step4.button')}</Button>
                </div>
            </div>
        </div>}
        </form>
    </FormProvider>)
});

type Inputs = {
    requestDate: string,
    fullName: string,
    email: string,
    phone: string,
    phone2: string,
};

export default Step4;
