import React, {useContext} from 'react';
import './PrivacyPolicy.scss';
import {i18nContext} from '../../components/i18n/I18nProvider';

const PrivacyPolicy: React.FC<any> = () => {
    const {i18n} = useContext(i18nContext);
    return (<div className="privacy-policy">
        <h1>{i18n.t('privacy.title')}</h1>
        <section className="policy">
            <h3>{i18n.t('privacy.policy.title')}</h3>
            <p>
                {i18n.t('privacy.policy.description1')}
            </p>
            <p>
                {i18n.t('privacy.policy.description2')}
            </p>
            <p>
                {i18n.t('privacy.policy.description3')}
            </p>
            <p>
                {i18n.t('privacy.policy.description4')}
            </p>
        </section>
        <section className="waiting-minors">
            <h3>{i18n.t('privacy.waiting-minors.title')}</h3>
            <p>
                {i18n.t('privacy.waiting-minors.description1')}
            </p>
            <p>
                {i18n.t('privacy.waiting-minors.description2')}
            </p>
            <p>
                {i18n.t('privacy.waiting-minors.description3')}
            </p>
        </section>
        <section className="normative-frameworks">
            <h3>{i18n.t('privacy.normative-frameworks.title')}</h3>
            <p>
                {i18n.t('privacy.normative-frameworks.description1')}
            </p>
        </section>
        <section className="personal-information">
            <h3>{i18n.t('privacy.personal-information.title')}</h3>
            <ul>
                <li>
                    {i18n.t('privacy.personal-information.list.1')}
                </li>
                <li>
                    {i18n.t('privacy.personal-information.list.2')}
                </li>
                <li>
                    {i18n.t('privacy.personal-information.list.3')}
                </li>
                <li>
                    {i18n.t('privacy.personal-information.list.4')}
                </li>
            </ul>
        </section>
        <section className="security">
            <h3>
                {i18n.t('privacy.security.title')}
            </h3>
            <p>
                {i18n.t('privacy.security.description1')}
            </p>
            <p>
                {i18n.t('privacy.security.description2')}
            </p>
            <p>
                {i18n.t('privacy.security.description3')}
            </p>
            <p>
                {i18n.t('privacy.security.description4')}
            </p>
            <p>
                {i18n.t('privacy.security.description5')}
            </p>
        </section>
        <section className="third-parties">
            <h3>
                {i18n.t('privacy.third-parties.title')}
            </h3>
            <p>
                {i18n.t('privacy.third-parties.description1')}
            </p>
            <ul>
                <li>
                    {i18n.t('privacy.third-parties.list.1')}
                </li>
                <li>
                    {i18n.t('privacy.third-parties.list.2')}
                </li>
            </ul>
        </section>
        <section className="refusal-policy">
            <h3>
                {i18n.t('privacy.refusal-policy.title')}
            </h3>
            <p>
                {i18n.t('privacy.refusal-policy.description1')}
            </p>
        </section>
        <div className="policy-updated-date">
            <i>{i18n.t('privacy.updated')}</i>
        </div>
    </div>);
}

export default PrivacyPolicy;
