import React, {useContext, useEffect, useState} from 'react';
import './Step3.scss';
import {i18nContext} from '../../../../../../components/i18n/I18nProvider';
import {RealEstateExtra, realEstateStoreContext} from '../../../../../../store/components/RealEstateStore';
import Button from '../../../../../../components/ui/Button/Button';
import {extraIconsMap} from '../../../../../../utils/functions';

type Step3Props = {
    onNextStep: () => void;
}

type extraActive = {
    [key: string]: boolean;
}

const Step3: React.FC<Step3Props> = (props) => {
    const {i18n} = useContext(i18nContext);
    const realEstateStoreCtx = useContext(realEstateStoreContext);
    const extras = realEstateStoreCtx.getRealEstateExtras();
    const [extraActive, setExtraActive] = useState<extraActive>(extras.reduce((acc, extra) => {
        acc[extra.id] = !!(realEstateStoreCtx.realEstate.extras || []).find((id) => id === extra.id);
        return acc;
    }, {} as extraActive));
    useEffect(() => {
        realEstateStoreCtx.mergeRealState({extras: Object.keys(extraActive).reduce((acc, id) => {
                if (extraActive[id]) {
                    acc.push(+id);
                }
                return acc;
            }, [] as number[])});
    }, [extraActive, realEstateStoreCtx]);
    const handleToggleExtra = (extra: RealEstateExtra) => {
        const value = !extraActive[extra.id]
        setExtraActive({...extraActive, ...{[extra.id]: value}});
    }
    const handleNextStep = () => {
        props.onNextStep();
    }
    return (<>
        <div className="modal-content">
            <div className="row center-xs center-sm center-md center-lg center-xl">
                <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 step-description">
                    <h3>{i18n.t('sellModal.step3.title')}</h3>
                </div>
            </div>
            <div className="extras-block">
                {extras.map((extra) => {
                    return (<div className="extra" key={extra.id}>
                        <Button
                            className={`button button--circle button--small button--ha-start ${extraActive[extra.id] ? '' : 'button--light'}`}
                            onClick={() => handleToggleExtra(extra)}>
                            {/*@ts-ignore*/}
                            <i className={`${extraIconsMap[extra.id]}`}/>
                            {i18n.t(`extras.${extra.name}`)}
                        </Button>
                    </div>)
                })}
            </div>
        </div>
        <div className="modal-actions">
            <div className="row center-xs">
                <div className="col-xs-12 col-sm-8 col-md-4 col-lg-4 ta-center">
                    <Button className="button" onClick={handleNextStep}>{i18n.t('sellModal.step3.button')}</Button>
                </div>
            </div>
        </div>
    </>)
};

export default Step3;
