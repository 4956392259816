import React from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './Datepicker.scss';

const Datepicker: React.FC<any> = (props) => {
    return (<DatePicker {...props} />);
};

export default Datepicker;
