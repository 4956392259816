import React, {useContext} from 'react';
import card1 from '../Home/images/card1.webp';
import card2 from '../Home/images/card2.webp';
import card3 from '../Home/images/card3.webp';
import {i18nContext} from '../../components/i18n/I18nProvider';
import './Contacts.scss';
import Button from '../../components/ui/Button/Button';
import {useOutletContext} from 'react-router-dom';
import {Map, Marker} from '@vis.gl/react-google-maps';

const Contacts: React.FC<{}> = () => {
    const {i18n} = useContext(i18nContext);
    const {setFeedbackModalProps} = useOutletContext<any>();

    return (<div className="contacts">
        <section className="section-content" id={"about"}>
            <div className="row middle-sm middle-xl middle-md middle-lg middle-xs center-xs center-sm center-md center-lg">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h2>{i18n.t('buy.why.title')}</h2>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <p>
                        {i18n.t('buy.why.descriptionTop')}
                    </p>
                    <p className="hide-xs hide-sm show-md show-xl show-lg">
                        {i18n.t('buy.why.descriptionBottom')}
                    </p>
                </div>
            </div>
        </section>
        <section className="home__cards">
            <div className="home__cards-wrapper">
                <div className="home__cards-card">
                    <div className="home__cards-card-image">
                        <img src={card1} alt="" />
                    </div>
                    <div className="home__cards-card-description">
                        <h5>{i18n.t('buy.cards.card1.title')}</h5>
                        <p>{i18n.t('buy.cards.card1.description')}</p>
                    </div>
                </div>
                <div className="home__cards-card">
                    <div className="home__cards-card-image">
                        <img src={card2} alt="" />
                    </div>
                    <div className="home__cards-card-description">
                        <h5>{i18n.t('buy.cards.card2.title')}</h5>
                        <p>{i18n.t('buy.cards.card2.description')}</p>
                    </div>
                </div>
                <div className="home__cards-card">
                    <div className="home__cards-card-image">
                        <img src={card3} alt="" />
                    </div>
                    <div className="home__cards-card-description">
                        <h5>{i18n.t('buy.cards.card3.title')}</h5>
                        <p>{i18n.t('buy.cards.card3.description')}</p>
                    </div>
                </div>
            </div>
        </section>
        <section id={"about"}>
            <div className="row middle-sm middle-xl middle-md middle-lg middle-xs center-xs center-sm center-md center-lg">
                <div className="col-xs-12 col-sm-8 col-md-5 col-lg-4">
                    <div>
                        <Button type="button" onClick={() => setFeedbackModalProps({isOpen: true, feedbackType: 'opinion'})}>
                            {i18n.t('header.links.contacts')}
                        </Button>
                    </div>
                </div>
            </div>
        </section>
        <div className="contacts-map-wrapper">
            <Map
                style={{width: '100%', height: '300px'}}
                defaultCenter={{lat: 41.396240050403584, lng: 2.144812362730612}}
                defaultZoom={18}
                gestureHandling={'greedy'}
                disableDefaultUI={true}
            >
                <Marker position={{lat: 41.396240050403584, lng: 2.144812362730612}} />
            </Map>
        </div>
    </div>);
}

export default Contacts;
