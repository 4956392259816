import React, {InputHTMLAttributes} from 'react';
import './FileCurrency.scss';

const FieldCurrency: React.FC<InputHTMLAttributes<any>&{hideCurrencySymbol?: boolean;}> = (props) => {
    const formatNumber = (number: string) => {
        // format number 1000000 to 1,234,567
        return number.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }

    const formatCurrency = (input: HTMLInputElement, blur: string | null) => {
        // appends $ to value, validates decimal side
        // and puts cursor back in right position.

        // get input value
        let input_val = input.value;

        // don't validate empty input
        if (input_val === '') { return; }

        // original length
        const original_len = input_val.length;

        // initial caret position
        let caret_pos = input.selectionStart || 0;

        // // check for decimal
        // if (input_val.indexOf('.') >= 0) {
        //
        //     // get position of first decimal
        //     // this prevents multiple decimals from
        //     // being entered
        //     const decimal_pos = input_val.indexOf('.');
        //
        //     // split number by decimal point
        //     let left_side = input_val.substring(0, decimal_pos);
        //     let right_side = input_val.substring(decimal_pos);
        //
        //     // add commas to left side of number
        //     left_side = formatNumber(left_side);
        //
        //     // validate right side
        //     right_side = formatNumber(right_side);
        //
        //     // On blur make sure 2 numbers after decimal
        //     if (blur === 'blur') {
        //         right_side += '00';
        //     }
        //
        //     // Limit decimal to only 2 digits
        //     right_side = right_side.substring(0, 2);
        //
        //     // join number by .
        //     if (props.hideCurrencySymbol) {
        //         input_val = left_side + '.' + right_side;
        //     } else {
        //         input_val = '€' + left_side + '.' + right_side;
        //     }
        //
        //
        // } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = formatNumber(input_val);
            if (!props.hideCurrencySymbol) {
                input_val = '€' + input_val;
            }

            // final formatting
            if (blur === 'blur') {
                input_val += '.00';
            }
        // }

        // send updated string to input
        input.value = input_val;

        // put caret back in the right position
        const updated_len = input_val.length;
        caret_pos = updated_len - original_len + caret_pos;
        input.setSelectionRange(caret_pos, caret_pos);
    }

    const handleChange = (event: any) => formatCurrency(event.target, null);
    const handleBlur = (event: any) => formatCurrency(event.target, null);

    return (<input
        onInput={handleChange}
        onBlur={handleBlur}
        autoComplete={'off'}
        type="text"
        className="field-currency"
        {...props} />)
}

export default FieldCurrency;
