import React, {InputHTMLAttributes} from 'react';
import './Checkbox.scss';
import {useFormContext} from 'react-hook-form';

const Checkbox: React.FC<InputHTMLAttributes<any>&{registerProps?: any}> = React.forwardRef(({registerProps, children, ...props}, ref) => {
    const methods = useFormContext();

    if (registerProps && methods) {
        return (<label className={`checkbox ${methods && methods.formState.errors && methods.formState.errors[registerProps.name] ? 'checkbox-invalid' : ''}`}>
            <input
                type="checkbox"
                {...methods.register(registerProps.name, registerProps.options)}
                {...props}
            />
            <span className={`checkmark`}></span>
            {children}
        </label>);
    }

    return (<label className="checkbox">
        <input type="checkbox" {...props} />
        <span className="checkmark"></span>
        {children}
    </label>);
})

export default Checkbox;
