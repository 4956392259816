import React from 'react';
import './EmptyPlaceholder.scss';
import Button from '../../../../components/ui/Button/Button';
import {useOutletContext} from 'react-router-dom';

const EmptyPlaceholder: React.FC<{}> = (props) => {
    const {setClientModalProps} = useOutletContext<any>();
    return (<div className="catalog-empty-wrapper-placeholder">
        <div className="catalog-empty-placeholder">
            <div className="ta-center catalog-empty-placeholder-row">
                <i className="icon-location"/>
            </div>
            <div className="ta-center catalog-empty-placeholder-row catalog-empty-placeholder__description">
                Unfortunately, no results match your search
            </div>
            <div className="ta-center catalog-empty-placeholder-row">
                <Button onClick={() => setClientModalProps({isOpen: true})}>
                    Make buy request
                </Button>
            </div>
        </div>
    </div>)
}

export default EmptyPlaceholder;
