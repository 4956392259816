import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import './Step3.scss';
import Button from '../../../../../../components/ui/Button/Button';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {Client, clientsStoreContext} from '../../../../../../store/components/ClientStore';
import FieldText from '../../../../../../components/ui/FieldText/FieldText';
import FieldRegexp from '../../../../../../components/ui/FieldRegexp/FieldRegexp';
import Textarea from '../../../../../../components/ui/Textarea/Textarea';
import Select from '../../../../../../components/ui/Select/Select';
import {observer} from 'mobx-react';
import {i18nContext} from '../../../../../../components/i18n/I18nProvider';
import {clone} from '../../../../../../utils/functions';
import FieldPhone from '../../../../../../components/ui/FieldPhone/FieldPhone';

type Step3Props = {
    onNextStep: () => void;
    onSubmitSuccess: (isSuccess: boolean) => void;
}

const Step3: React.FC<Step3Props> = observer((props) => {
    const {i18n, locale} = useContext(i18nContext);
    const clientStoreCtx = useContext(clientsStoreContext);
    const deadlines = useMemo(() => clone(deadlineOptions).map((deadline: SelectDeadline) => {
        deadline.name = i18n.translations[locale].buyDeadlines[deadline.name];
        return deadline;
    }), [i18n.translations, locale]);
    const getDeadline = useCallback(() => {
        const buyDeadline = clientStoreCtx.client.buyDeadline;
        const value = buyDeadline && deadlines.find((d: SelectDeadline) => d.value === buyDeadline);
        return value ? value : null;
    }, [clientStoreCtx.client.buyDeadline, deadlines]);
    const methods = useForm<Inputs>({mode: 'onChange'});
    const {handleSubmit} = methods;
    const [isRequest, setRequest] = useState(false);
    const [deadline, setDeadline] = useState<SelectDeadline | null>(getDeadline());

    useEffect(() => setDeadline(getDeadline()), [clientStoreCtx.client.buyDeadline, getDeadline]);
    const handleChangeDeadline = (deadline: SelectDeadline) => {
        clientStoreCtx.mergeClient({buyDeadline: deadline.value});
    }
    const handleChangeFieldText = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fieldName = event.target.name
        clientStoreCtx.mergeClient({[fieldName]: event.target.value});
        if (fieldName === 'phone') {
            methods.setValue('phone2', event.target.value);
            methods.trigger('phone2').then(() => {});
        }
    }
    const onSubmit: SubmitHandler<Inputs> = () => {
        const data: Client = JSON.parse(JSON.stringify(clientStoreCtx.getClient()));
        let key: string;
        for (key in data) {
            // @ts-ignore
            if (data[key] === '') {
                // @ts-ignore
                data[key] = null;
            }
            // @ts-ignore
            if (key === 'phone' && data[key] === null) {
                // @ts-ignore
                data.phoneCode = null;
            }
        }
        setRequest(true);
        clientStoreCtx.createClient(data)
            .then((result) => {
                if (result) {
                    props.onSubmitSuccess(true);
                }
            }).finally(() => setRequest(false))
    }

    return (<FormProvider {...methods} >
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-content">
                <div className="row center-xs center-sm center-md center-lg center-xl">
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                        <Select
                            items={deadlines}
                            initBy={deadline}
                            textKey={'name'}
                            callback={handleChangeDeadline}
                            defaultText={i18n.t('clientModal.step3.salesPlaceholder')}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                        <FieldText
                            placeholder={i18n.t('clientModal.step3.fullNamePlaceholder')}
                            value={clientStoreCtx.client.fullName ?? ''}
                            registerProps={{name: 'fullName', options: {required: true, onChange: handleChangeFieldText}}}
                        />
                    </div>
                </div>
                <div className="row center-xs center-sm center-md center-lg center-xl">

                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                        <FieldText
                            placeholder={i18n.t('clientModal.step3.emailPlaceholder')}
                            value={clientStoreCtx.client.email ?? ''}
                            registerProps={{name: 'email', options: {required: true, pattern: /^\S+@\S+$/i, onChange: handleChangeFieldText}}}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                        <input type="hidden" {...methods.register('phone2', {required: true, onChange: handleChangeFieldText})}/>
                        <FieldPhone
                            type="tel"
                            className={`field-phone ${methods && methods.formState.errors && methods.formState.errors.phone2 ? 'field-phone--invalid' : ''}`}
                            placeholder={'_________'}
                            value={clientStoreCtx.client.phone ?? ''}
                            onChange={handleChangeFieldText}
                            name={'phone'}
                            maxLength={9}
                            phoneCode={clientStoreCtx.client.phoneCode ?? ''}
                            onChangePhoneCode={(phoneCode) => clientStoreCtx.mergeClient({phoneCode})}
                        />
                    </div>
                </div>
                <div className="row center-xs center-sm center-md center-lg center-xl">
                    <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                        <Textarea
                            name="description"
                            value={clientStoreCtx.client.description}
                            onChange={handleChangeFieldText}
                            placeholder={i18n.t('clientModal.step3.descriptionPlaceholder')}
                        />
                    </div>
                </div>
            </div>
            <div className="modal-actions">
                <div className="row center-xs">
                    <div className="col-xs-12 col-sm-8 col-md-4 col-lg-4 ta-center">
                        <Button disabled={isRequest} className="button" type="submit">{i18n.t('clientModal.step3.button')}</Button>
                    </div>
                </div>
            </div>
        </form>
    </FormProvider>)
});

type SelectDeadline = {
    id: number;
    name: string;
    value: string;
}

const deadlineOptions: SelectDeadline[] = [
    {
        id: 1,
        name: 'Now',
        value: 'now'
    },
    {
        id: 2,
        name: 'Within six months',
        value: 'within six months'
    },
    {
        id: 3,
        name: 'During the year',
        value: 'during the year'
    },
    {
        id: 4,
        name: 'A year or more',
        value: 'a year or more'
    },
]

type Inputs = {
    fullName: string,
    email: string,
    phone: string,
    phone2: string,
};

export default Step3;
