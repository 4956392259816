import React, {useContext} from 'react';
import {observer} from 'mobx-react';
import './Catalog.scss';
import Filter from './components/Filter/Filter';
import {RealEstate, realEstateStoreContext} from '../../store/components/RealEstateStore';
import RealEstateCard from './components/RealEstateCard/RealEstateCard';
import RealEstateCardSkileton from './components/RealEstateCardSkileton/RealEstateCardSkileton';
import EmptyPlaceholder from './components/EmptyPlaceholder/EmptyPlaceholder';

const Catalog: React.FC<{}> = observer(() => {
    const realEstateStoreCtx = useContext(realEstateStoreContext);
    const realEstates = realEstateStoreCtx.getRealEstates();
    return (<div className="catalog">
        <Filter />
        {realEstateStoreCtx.isCatalogLoading && <div className="catalog-cards">
            {[2, 4, 5, 6, 9].map((id) => <RealEstateCardSkileton key={'real_estate_skileton' + id} />)}
        </div>}
        {!realEstateStoreCtx.isCatalogLoading && realEstates.length > 0 && <div className="catalog-cards">
            {realEstates.map((realEstate: RealEstate) => <RealEstateCard realEstate={realEstate} key={realEstate.id}/>)}
        </div>}
        {!realEstateStoreCtx.isCatalogLoading && realEstates.length === 0 &&
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <EmptyPlaceholder />
                </div>
            </div>}
    </div>);
});

export default Catalog;
