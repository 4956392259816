import React, {useContext} from 'react';

import Modal from '../../../../components/ui/Modal/Modal';
//import {i18nContext} from '../../../../components/i18n/I18nProvider';
import './BannerModal.scss';
import banner from './images/banner.png';
import Button from '../../../../components/ui/Button/Button';
import {i18nContext} from '../../../../components/i18n/I18nProvider';

const BannerModal: React.FC<{
    onClose: (isClose: boolean) => void;
    onSuccess: () => void;
}> = (props) => {
    const {i18n} = useContext(i18nContext);
    const handleCloseModal = () => {
        props.onClose && props.onClose(false);
        document.body.classList.remove('ReactModal__Body--open');
    }

    const handleSuccessModal = () => {
        props.onSuccess && props.onSuccess();
    }

    return (<Modal
        className={'ReactModal__Content-banner'}
        isOpen={true}
        onRequestClose={handleCloseModal}
    >
        <div className="modal-content">
            <i className="icon-close" onClick={handleCloseModal}/>
            <div className="ReactModal__Content-banner-image-wrapper">
                <img src={banner} className="ReactModal__Content-banner-image" alt={'Selecta'}/>
            </div>
            <div className="row between-xs">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h2>{i18n.t('bannerModal.title')}</h2>
                    <p>{i18n.t('bannerModal.description')}</p>
                </div>
            </div>
        </div>
        <div className="modal-actions">
            <div className="row center-xs">
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 ta-center">
                    <Button className="button button--light button--circle" type="submit" onClick={handleCloseModal}>{i18n.t('bannerModal.button1Text')}</Button>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-8 col-lg-8 ta-center">
                    <Button className="button button--circle" type="submit" onClick={handleSuccessModal}>{i18n.t('bannerModal.button2Text')}</Button>
                </div>
            </div>
        </div>
    </Modal>);
}

export default BannerModal;
