import React from 'react';
import ReactModal, {Props} from 'react-modal';
import './Modal.scss';

ReactModal.setAppElement('#root');
const Modal: React.FC<Props> = (props) => {
    return <ReactModal {...props} />
}

export default Modal;
