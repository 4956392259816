import React, {useContext, useEffect, useMemo, useState} from 'react';
import './Step2.scss';
import {observer} from 'mobx-react';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {i18nContext} from '../../../../../../components/i18n/I18nProvider';
import {
    Condition,
    conditions,
    realEstateStoreContext,
} from '../../../../../../store/components/RealEstateStore';
import Select from '../../../../../../components/ui/Select/Select';
import FieldRegexp from '../../../../../../components/ui/FieldRegexp/FieldRegexp';
import FieldCounter from '../../../../../../components/ui/FieldCounter/FieldCounter';
import Button from '../../../../../../components/ui/Button/Button';

type Step2Props = {
    onNextStep: () => void;
}

const Step2: React.FC<Step2Props> = observer((props) => {
    const {i18n, locale} = useContext(i18nContext);
    const methods = useForm<Inputs>({mode: 'onChange'});
    const {handleSubmit} = methods;
    const realEstateStoreCtx = useContext(realEstateStoreContext);
    const [energy, setEnergy] = useState<Energy | null>(null);
    const [energyClass, setEnergyClass] = useState<EnergyClass | null>(null);
    const [condition, setCondition] = useState<Condition | null>(null);
    const conditionsTranslated = useMemo(() => JSON.parse(JSON.stringify(conditions)).map((condition: Condition) => {
        condition.name = i18n.translations[locale].conditions[condition.name];
        return condition;
    }), [locale, i18n.translations]);

    useEffect(() => {
        const energyCertificate = realEstateStoreCtx.realEstate.energyCertificate;
        if (energyCertificate === null) {
            return;
        }
        const energyValue = energyCertificate && energies.find((energy) => energy.value === energyCertificate);
        setEnergy(typeof energyValue === 'object' ? energyValue : null);
    }, [realEstateStoreCtx.realEstate.energyCertificate]);

    useEffect(() => {
        const condition = realEstateStoreCtx.realEstate.condition;
        const conditionValue = condition && conditionsTranslated.find((con: Condition) => con.value === condition);
        setCondition(conditionValue ? conditionValue : null);
    }, [realEstateStoreCtx.realEstate.condition, conditionsTranslated]);

    useEffect(() => {
        const energyClass = realEstateStoreCtx.realEstate.energyClass;
        const energyClassValue = energyClass && energyClasses.find((cl) => cl.value === energyClass);
        setEnergyClass(energyClassValue ? energyClassValue : null);
    }, [realEstateStoreCtx.realEstate.energyClass]);

    const handleChangeCondition = (condition: Condition) => {
        realEstateStoreCtx.mergeRealState({condition: condition.value});
    }
    const handleChangeEnergy = (energy: Energy) => {
        realEstateStoreCtx.mergeRealState({energyCertificate: energy.value});
    }

    const handleChangeEnergyClass = (energyClass: EnergyClass) => {
        realEstateStoreCtx.mergeRealState({energyClass: energyClass.value});
    }

    const handleChangeFieldNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        realEstateStoreCtx.mergeRealState({[event.target.name]: event.target.value});
    }
    const handleChangeBedroomsCount = (count: number) => {
        realEstateStoreCtx.mergeRealState({bedroomCount: count});
    }

    const handleChangeBathroomCount = (count: number) => {
        realEstateStoreCtx.mergeRealState({bathroomCount: count});
    }
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        const realEstate = realEstateStoreCtx.realEstate;
        realEstateStoreCtx.mergeRealState({
            livingArea: realEstate.livingArea ? +realEstate.livingArea : '',
            plotArea: realEstate.plotArea ? +realEstate.plotArea : '',
            buildingYear: realEstate.buildingYear ? +realEstate.buildingYear : '',
        })
        props.onNextStep();
    }

    const isCommercial = ['commercial', 'land'].includes(realEstateStoreCtx.realEstate.realEstateType);

    return (<FormProvider {...methods} >
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-content">
            <div className="row center-xs center-sm center-md center-lg center-xl middle-xs middle-sm middle-md middle-lg middle-xl">
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                    <Select
                        items={conditionsTranslated}
                        initBy={condition}
                        textKey={'name'}
                        callback={handleChangeCondition}
                        defaultText={i18n.t('sellModal.step2.conditionPlaceholder')}
                        registerProps={{name: 'condition', options: {required: true}}}
                    />
                </div>
                {/*<div className="col-xs-12 col-sm-3 col-md-2 col-lg-2 ta-right">*/}
                {/*    {i18n.t('sellModal.step2.buildingYear')}*/}
                {/*</div>*/}
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                    <FieldRegexp
                        placeholder={i18n.t('sellModal.step2.yearPlaceholder')}
                        regexp={'[^0-9.]'}
                        maxLength={4}
                        name={'buildingYear'}
                        type="tel"
                        value={realEstateStoreCtx.realEstate.buildingYear ? realEstateStoreCtx.realEstate.buildingYear : ''}
                        onChange={handleChangeFieldNumber}
                    />
                </div>
            </div>
            <div className="row center-xs center-sm center-md center-lg center-xl middle-xs middle-sm middle-md middle-lg middle-xl">
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 field-with-postfix">
                    <FieldRegexp
                        placeholder={i18n.t('sellModal.step2.plotAreaPlaceholder')}
                        name="plotArea"
                        regexp={'[^0-9.]'}
                        type="tel"
                        value={realEstateStoreCtx.realEstate.plotArea ? realEstateStoreCtx.realEstate.plotArea : ''}
                        onChange={handleChangeFieldNumber}
                    />
                    <span className="field-postfix">m2</span>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 field-with-postfix">
                    <FieldRegexp
                        registerProps={{name: 'livingArea', options: {required: true}}}
                        placeholder={i18n.t('sellModal.step2.livingAreaPlaceholder')}
                        regexp={'[^0-9.]'}
                        type="tel"
                        value={realEstateStoreCtx.realEstate.livingArea ? realEstateStoreCtx.realEstate.livingArea : ''}
                        onChange={handleChangeFieldNumber}
                    />
                    <span className="field-postfix">m2</span>
                </div>
            </div>
            {
                realEstateStoreCtx.realEstate.realEstateType !== 'land' && <div className="row center-xs center-sm center-md center-lg center-xl middle-xs middle-sm middle-md middle-lg middle-xl">
                    <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2 ta-left">
                        {isCommercial ? i18n.t('sellModal.step2.bathroomsNoRequired') : i18n.t('sellModal.step2.bathrooms')}
                    </div>
                    <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2">
                        <FieldCounter
                            count={realEstateStoreCtx.realEstate.bathroomCount ? +realEstateStoreCtx.realEstate.bathroomCount : 0}
                            onChange={handleChangeBathroomCount}
                            registerProps={{name: 'bathroomCount', options: isCommercial ? {} : {required: true}}}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2 ta-left">
                        {isCommercial ? i18n.t('sellModal.step2.bedroomsNoRequired') : i18n.t('sellModal.step2.bedrooms')}
                    </div>
                    <div className="col-xs-12 col-sm-3 col-md-2 col-lg-2">
                        <FieldCounter
                            count={realEstateStoreCtx.realEstate.bedroomCount ? +realEstateStoreCtx.realEstate.bedroomCount : 0}
                            onChange={handleChangeBedroomsCount}
                            registerProps={{name: 'bedroomCount', options: isCommercial ? {} : {required: true}}}
                        />
                    </div>
                </div>
            }
            <div className="row center-md center-lg center-xl middle-xs middle-sm middle-md middle-lg middle-xl">
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                    <Select
                        items={energies}
                        initBy={energy}
                        textKey={'name'}
                        callback={handleChangeEnergy}
                        defaultText={i18n.t('sellModal.step2.energyPlaceholder')}
                        className={"select select-top"}
                    />
                </div>
                {realEstateStoreCtx.realEstate.energyCertificate ?
                    <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 ta-center">
                         <Select
                            items={energyClasses}
                            initBy={energyClass}
                            textKey={'name'}
                            callback={handleChangeEnergyClass}
                            defaultText={i18n.t('sellModal.step2.energyClassPlaceholder')}
                            registerProps={{name: 'energyClass', options: {required: true}}}
                            className={"select select-top"}
                        />
                    </div> : <div className="col-md-4 col-lg-4 ta-center"></div>}
            </div>
        </div>
        <div className="modal-actions">
            <div className="row center-xs">
                <div className="col-xs-12 col-sm-8 col-md-4 col-lg-4 ta-center">
                    <Button className="button" type="submit">{i18n.t('sellModal.step2.button')}</Button>
                </div>
            </div>
        </div>
        </form>
    </FormProvider>)
});

export type Energy = {
    id: number;
    name: string;
    value: boolean
}

export type EnergyClass = {
    id: number;
    name: string;
    value: string
}

type Inputs = {
    condition: string,
    livingArea: string,
    bathroomCount: string,
    bedroomCount: string,
    energyCertificate: string,
    energyClass: string,
};

const energyClasses: EnergyClass[] = [
    {id: 1, name: 'A', value: 'A'},
    {id: 2, name: 'B', value: 'B'},
    {id: 2, name: 'C', value: 'C'},
    {id: 2, name: 'D', value: 'D'},
    {id: 2, name: 'E', value: 'E'},
    {id: 2, name: 'F', value: 'F'},
    {id: 2, name: 'G', value: 'G'},
]

const energies: Energy[] = [
    {id: 1, name: 'Yes', value: true},
    {id: 2, name: 'No', value: false},
]

export default Step2;
