import React, {useContext, useState} from 'react';
import buy from './images/buy.webp';
import consultation from './images/consultation.jpeg';
import evaluation from './images/evaluation.png';
import publication from './images/publication.png';
import individualMarketing from './images/individualMarkiting.png';
import viewings from './images/viewings.png';
import checkDocuments from './images/checkDocuments.png';
import salesContract from './images/salesContract.png';
import professional from './images/professional.webp';
import accurate from './images/accurate.webp';
import tailored from './images/tailored.webp';
import selective from './images/selective.webp';
import clear from './images/clear.webp';
import './HowItWorks.scss';

import Button from '../../components/ui/Button/Button';
import {i18nContext} from '../../components/i18n/I18nProvider';
import {useOutletContext} from 'react-router-dom';
import {observer} from 'mobx-react';

const HowItWorks: React.FC<{}> = observer((props) => {
    const {i18n} = useContext(i18nContext);
    const {setFeedbackModalProps, setPrivacyModalProps, setClientModalProps} = useOutletContext<any>();
    const [activeStep, setActiveStep] = useState('Consultation');
    const handleOpenEvaluate = () => {
        window.scrollTo(0, 0);
        setPrivacyModalProps({isOpen: true})
    }

    const handleOpenBuy = () => {
        window.scrollTo(0, 0);
        setClientModalProps({isOpen: true})
    }

    const handleSetActiveStep = (step: string) => {
        setActiveStep(step);
    }

    const handleOpenFeedback = (step: string) => {
        setFeedbackModalProps({isOpen: true, feedbackType: step.toLowerCase()});
    }

    return (<div className="how-it-works">
        <section className="buy-proccess">
            <div className="row between-xs between-sm between-md between-lg middle-xs middle-sm middle-md middle-lg section-buy section-content">
                <div className="col-xs-12 col-sm-12 col-md-5 col-lg-4">
                    <img src={buy} alt="buy"/>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-8">
                    <h3>{i18n.t('howItWorks.buy.title')}</h3>
                    <p>
                        {i18n.t('howItWorks.buy.description1')}
                    </p>
                    <div className="home__about-actions">
                        <Button onClick={handleOpenBuy}>{i18n.t('howItWorks.buy.button')}</Button>
                    </div>
                </div>
            </div>
        </section>
        <section className="sell-proccess section-content">
            <div className="sell-proccess-description">
                <h3>{i18n.t('howItWorks.sell.title')}</h3>
                <p>
                    {i18n.t('howItWorks.sell.description1')}
                </p>
            </div>
            <div className="row sell-row-cards">
                <div className="sell-row-card professional">
                    <div className="sell-row-card__image">
                        <img src={professional} alt={'domus'}/>
                    </div>
                    <div className="sell-row-card__description">
                        <h5>
                            {i18n.t('howItWorks.cards.professionalGuidance.title')}
                        </h5>
                        <p>
                            {i18n.t('howItWorks.cards.professionalGuidance.description')}
                        </p>
                    </div>
                </div>
                <div className="sell-row-card accurate">
                    <div className="sell-row-card__image">
                        <img src={accurate} alt={'domus'}/>
                    </div>
                    <div className="sell-row-card__description">
                        <h5>
                            {i18n.t('howItWorks.cards.accurateValuation.title')}
                        </h5>
                        <p>
                            {i18n.t('howItWorks.cards.accurateValuation.description')}
                        </p>
                    </div>
                </div>
                <div className="sell-row-card marketing">
                    <div className="sell-row-card__image">
                        <img src={tailored} alt={'domus'}/>
                    </div>
                    <div className="sell-row-card__description">
                        <h5>
                            {i18n.t('howItWorks.cards.tailoredMarketing.title')}
                        </h5>
                        <p>
                            {i18n.t('howItWorks.cards.tailoredMarketing.description')}
                        </p>
                    </div>
                </div>
                <div className="sell-row-card clients">
                    <div className="sell-row-card__image">
                        <img src={selective} alt={'domus'}/>
                    </div>
                    <div className="sell-row-card__description">
                        <h5>
                            {i18n.t('howItWorks.cards.selectiveClientBase.title')}
                        </h5>
                        <p>
                            {i18n.t('howItWorks.cards.selectiveClientBase.description')}
                        </p>
                    </div>
                </div>
                <div className="sell-row-card process">
                    <div className="sell-row-card__image">
                        <img src={clear} alt={'domus'}/>
                    </div>
                    <div className="sell-row-card__description">
                        <h5>
                            {i18n.t('howItWorks.cards.clearProcess.title')}
                        </h5>
                        <p>
                            {i18n.t('howItWorks.cards.clearProcess.description')}
                        </p>
                    </div>
                </div>
            </div>
            <div className="home__about-actions">
                <Button onClick={handleOpenEvaluate}>{i18n.t('howItWorks.sell.button')}</Button>
            </div>
        </section>
        <section className="steps section-content">
            <h3 className="ta-center">{i18n.t('howItWorks.steps.headerTitle')}</h3>
            <div className="row between-xs between-sm between-md between-lg section-content">
                <div className="col-md-4 col-lg-3 hide-xs hide-sm show-md show-lg steps-nav">
                    <div className="steps-navs">
                        <div className="steps-nav-wrapper">
                            {
                                howToWorksSteps.map((stepItem) => {
                                    return (<div className={`steps-nav-item ${stepItem.name === activeStep ? 'steps-nav-item-active' : ''}`} key={stepItem.id}
                                                 onClick={() => handleSetActiveStep(stepItem.name)}>
                                        <div className="steps-nav-item-icon">
                                            <i className={`${stepItem.icon}`} />
                                        </div>
                                        <div className="steps-nav-item-title">
                                            {i18n.t(`howItWorks.steps.navSteps.${stepItem.name}`)}
                                        </div>
                                    </div>)
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-8 col-lg-9 hide-xs hide-sm show-md show-lg section-step">
                    <div className="row between-xs between-sm between-md between-lg middle-xs middle-sm middle-md middle-lg section-buy section-content">
                        <div className="col-xs-12 col-sm-12">
                            {activeStep === 'Consultation' ? <img src={consultation} alt="Consultation"/> : ''}
                            {activeStep === 'Evaluation' ? <img src={evaluation} alt="Evaluation"/> : ''}
                            {activeStep === 'Publication' ? <img src={publication} alt="Publication"/> : ''}
                            {activeStep === 'Individual Marketing' ? <img src={individualMarketing} alt="Individual Marketing"/> : ''}
                            {activeStep === 'Viewings' ? <img src={viewings} alt="Viewings"/> : ''}
                            {activeStep === 'Check documents' ? <img src={checkDocuments} alt="Check documents"/> : ''}
                            {activeStep === 'Sales Contract' ? <img src={salesContract} alt="Sales Contract"/> : ''}
                        </div>
                    </div>
                    <h3>
                        {/*@ts-ignore*/}
                        <i className={`${iconsMap[activeStep]}`}/>
                        {i18n.t(`howItWorks.steps.${activeStep}.title`)}
                    </h3>
                    <p>
                        {i18n.t(`howItWorks.steps.${activeStep}.description1`)}
                    </p>
                    <div className="home__about-actions">
                        <Button onClick={() => handleOpenFeedback(activeStep)}>{i18n.t('howItWorks.steps.Consultation.button')}</Button>
                    </div>
                </div>
            </div>
            {howToWorksSteps.map((stepItem) => {
                const name = stepItem.name;
                return (<div className="row between-xs between-sm between-md between-lg middle-xs middle-sm middle-md middle-lg section-buy section-content">
                    <div className="col-xs-12 col-sm-12 show-xs show-sm hide-md hide-lg section-step" key={stepItem.id}>
                        {name === 'Consultation' ? <img src={consultation} alt="Consultation"/> : ''}
                        {name === 'Evaluation' ? <img src={evaluation} alt="Evaluation"/> : ''}
                        {name === 'Publication' ? <img src={publication} alt="Publication"/> : ''}
                        {name === 'Individual Marketing' ? <img src={individualMarketing} alt="Individual Marketing"/> : ''}
                        {name === 'Viewings' ? <img src={viewings} alt="Viewings"/> : ''}
                        {name === 'Check documents' ? <img src={checkDocuments} alt="Check documents"/> : ''}
                        {name === 'Sales Contract' ? <img src={salesContract} alt="Sales Contract"/> : ''}
                        <h3>{i18n.t(`howItWorks.steps.${name}.title`)}</h3>
                        <p>
                            {i18n.t(`howItWorks.steps.${name}.description1`)}
                        </p>
                        <div className="home__about-actions">
                            <Button onClick={() => handleOpenFeedback(stepItem.name)}>{i18n.t(`howItWorks.steps.${name}.button`)}</Button>
                        </div>
                    </div>
                </div>)
            })}
        </section>
        <section className="section-content section-what-sets-us-apart">
            <div className="row">
                <div className="col-xs-12 col-sm-12 content-wrapper">
                    <div>
                        <h3>
                            {i18n.t(`howItWorks.whatSetsUsApart.title`)}
                        </h3>
                        <p>
                            {i18n.t(`howItWorks.whatSetsUsApart.description1`)}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>)
});

const howToWorksSteps = [
    {
        id: 1,
        name: 'Consultation',
        icon: 'icon-search-list'
    },
    {
        id: 2,
        name: 'Evaluation',
        icon: 'icon-dollar'
    },
    {
        id: 4,
        name: 'Individual Marketing',
        icon: 'icon-person'
    },
    {
        id: 5,
        name: 'Viewings',
        icon: 'icon-eye'
    },
    {
        id: 6,
        name: 'Check documents',
        icon: 'icon-search-folder'
    },
    {
        id: 7,
        name: 'Sales Contract',
        icon: 'icon-edit-folder'
    },
];

const iconsMap = {
    'Consultation': 'icon-search-list',
    'Evaluation': 'icon-dollar',
    'Publication': 'icon-earth-2',
    'Individual Marketing': 'icon-person',
    'Viewings': 'icon-eye',
    'Check documents': 'icon-search-folder',
    'Sales Contract': 'icon-edit-folder',
}

export default HowItWorks;
