import React, {InputHTMLAttributes} from 'react';
import './FieldRegexp.scss';
import {useFormContext} from 'react-hook-form';

const FieldRegexp: React.FC<InputHTMLAttributes<any>&{regexp: any, registerProps?: any}> =({onChange, registerProps, regexp, ...props}) => {
    const methods = useFormContext();
    const handleChange = (event: any) => {
        const reg = new RegExp(regexp, 'g');
        event.target.value = event.target.value.replace(reg, '');
        if (onChange) {
            onChange(event);
        }
    }
    if  (registerProps && methods) {
        return (<input
            autoComplete="off"
            type="text"
            className={`field-regexp ${methods && methods.formState.errors && methods.formState.errors[registerProps.name] ? 'field-regexp-invalid' : ''}`}
            {...methods.register(registerProps.name, {...registerProps.options, ...{onChange: handleChange}})}
            {...props}
        />);
    }

    return (<input
        autoComplete="off"
        type="text"
        className={`field-regexp`}
        onChange={handleChange}
        {...props}
    />);
}

export default FieldRegexp;
