import React, {InputHTMLAttributes} from 'react';
import './FieldText.scss';
import {useFormContext} from 'react-hook-form';

type FieldTextProps = {
    registerProps?: any;
}

export type Ref = HTMLInputElement;

const FieldText = React.forwardRef<Ref, React.InputHTMLAttributes<HTMLInputElement>&FieldTextProps>(({registerProps, ...props}, ref) => {
    const methods = useFormContext();

    if (registerProps && methods) {
        return (<input
            autoComplete={'off'}
            type="text"
            className={`field-text ${methods && methods.formState.errors && methods.formState.errors[registerProps.name] ? 'field-text-invalid' : ''}`}
            {...methods.register(registerProps.name, registerProps.options)}
            {...props}
            />
        );
    }
    return (<input
        autoComplete={'off'}
        type="text"
        className="field-text"
        {...props}
        ref={ref}
    />);
});

export default FieldText;
