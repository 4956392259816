import React, {useContext, useEffect, useState} from 'react';
import './RealEstateModal.scss';
import Step1 from './components/Step1/Step1';
import Step2 from './components/Step2/Step2';
import Step3 from './components/Step3/Step3';
import Step4 from './components/Step4/Step4';
import {i18nContext} from '../../../../components/i18n/I18nProvider';
import {realEstateStoreContext} from '../../../../store/components/RealEstateStore';
import Modal from '../../../../components/ui/Modal/Modal';

type RealEstateModalProps = {
    onClose: (isSuccess: boolean) => void;
}

const RealEstateModal: React.FC<RealEstateModalProps> = (props) => {
    const {i18n} = useContext(i18nContext);
    const realEstateStoreCtx = useContext(realEstateStoreContext);
    const [steps] = useState(4);
    const [step, setStep] = useState(1);
    useEffect(() => {
        realEstateStoreCtx.loadRealEstateExtras().then(() => {});
    }, [realEstateStoreCtx]);
    useEffect(() => {
        window.onbeforeunload = () => i18n.t('messages.unsavedDataWarning');
    }, [i18n]);
    const handleCloseModal = () => {
        const isClose = window.confirm(i18n.t('messages.unsavedDataWarning'));
        if (isClose) {
            window.onbeforeunload = () => {};
            props.onClose(false);
        }
        document.body.classList.remove('ReactModal__Body--open');
    }

    const handleBackStep = () => {
        setStep(step - 1);
    }

    const handleNextStep = () => {
        setStep(step + 1);
    }

    return (<Modal
        className={'ReactModal__Content-real-estate'}
        isOpen={true}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
    >
        <div className="modal-header">
            <div className="row between-xs">
                <div className="col-xs-2 col-sm-1 col-md-1 col-lg-1">
                    {
                        step > 1 && <i className="icon-arrow-left-2" onClick={handleBackStep}/>
                    }
                </div>
                <div className="col-xs-8 col-sm-10 col-md-10 col-lg-10 ta-center">
                    {i18n.t('sellModal.header.title')}
                </div>
                <div className="col-xs-2 col-sm-1 col-md-1 col-lg-1 ta-right">
                    <i className="icon-close" onClick={handleCloseModal}/>
                </div>
            </div>
        </div>
        <div className="steps-progress-wrapper">
            <div className="steps-progress">
                <div className="steps-progress-line" style={{width: step * (100 / steps) + '%'}}></div>
            </div>
            <div className="steps-progress-numbers ta-center">{step} / {steps}</div>
        </div>
        {step === 1 && <Step1 onNextStep={handleNextStep}/>}
        {step === 2 && <Step2 onNextStep={handleNextStep}/>}
        {step === 3 && <Step3 onNextStep={handleNextStep}/>}
        {step === 4 && <Step4 onNextStep={handleNextStep} onSubmitSuccess={(isSuccess: boolean) => props.onClose(isSuccess)}/>}
    </Modal>)
}

export default RealEstateModal;
