import React from 'react';
import './FieldStatic.scss';

type FieldStaticProps = {
    value: string;
    placeholder?: string;
    postfix?: string;
};

const FieldStatic: React.FC<FieldStaticProps> = ({postfix, value, placeholder, ...props}) => {
    return (<div className={`field-static ${postfix ? 'field-static--with-postfix' : ''}`} {...props}>
        {!value && placeholder && <div className="field-static__placeholder">{placeholder}</div>}
        {value}
        {!!postfix && <div className="field-static__postfix">{postfix}</div>}
    </div>);
}

export default FieldStatic;
