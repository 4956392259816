import React, {createContext, useCallback, useState} from 'react';
import {I18n} from 'i18n-js';
import en from '../i18n/en.json';
import es from '../i18n/es.json';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/es';

export type I18nLocale = {
    id: string;
    name: string;
};
export type I18nType = {
    i18n: I18n;
    locale: string;
    changeLocale: (loc: string) => void;
    locales: I18nLocale[];
};
export const i18nContext: React.Context<I18nType> = createContext(
    {} as I18nType,
);
export const getDeviceLocale = function () {
    // @ts-ignore
    let deviceLanguage = localStorage.getItem('locale') || window.navigator.language || window.navigator.userLanguage;
    deviceLanguage = deviceLanguage.split('_')[0];

    return ['es', 'en'].includes(deviceLanguage) ? deviceLanguage : 'en';
};

const I18nProvider: React.FC<{children: any}> = ({children}) => {
    const [locales] = useState([
        {
            id: 'es',
            name: 'Español',
        },
        {
            id: 'en',
            name: 'English',
        },
    ]);
    const [locale, setLocale] = useState(getDeviceLocale());
    const changeLocale = useCallback((l: string) => {
        setLocale(l);
        moment.locale(l === 'en' ? 'en-gb' : 'es');
    }, []);
    const i18n = new I18n({en, es});
    i18n.locale = locale;
    i18n.enableFallback = true;

    return (
        <i18nContext.Provider value={{i18n, locale, changeLocale, locales}}>
            {children}
        </i18nContext.Provider>
    );
};

export default I18nProvider;
