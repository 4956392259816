import React, {InputHTMLAttributes, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import Select from '../../../../components/ui/Select/Select';
import {
    Municipality,
    realEstateStoreContext,
    RealEstateType,
    realEstateTypes
} from '../../../../store/components/RealEstateStore';
import MunicipalitiesCombobox from '../../../../components/ui/MunicipalitiesCombobox/MunicipalitiesCombobox';
import FieldRegexp from '../../../../components/ui/FieldRegexp/FieldRegexp';
import {i18nContext} from '../../../../components/i18n/I18nProvider';
import {clone} from '../../../../utils/functions';
import {observer} from 'mobx-react';
import './Filter.scss';
import Popover from '../../../../components/ui/Popover/Popover';
import FieldStatic from '../../../../components/ui/FieldStatic/FieldStatic';
import Button from '../../../../components/ui/Button/Button';
import {useLocation, useNavigate} from 'react-router-dom';
import FieldCurrency from '../../../../components/ui/FieldCurrency/FieldCurrency';

const Filter: React.FC<InputHTMLAttributes<any>>  = observer(() => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const {i18n, locale} = useContext(i18nContext);
    const realEstateStoreCtx = useContext(realEstateStoreContext);
    const [bedroomsState, setBedroomsState] = useState({
        bedroomCountFrom: '',
        bedroomCountTo: '',
    });
    const [livingAreaState, setLivingAreaState] = useState({
        livingAreaFrom: '',
        livingAreaTo: '',
    });
    const [catalogPriceState, setCatalogPriceState] = useState({
        catalogPriceFrom: '',
        catalogPriceTo: '',
    });
    const realEstates = useMemo(() => clone(realEstateTypes).map((realEstate: RealEstateType) => {
        realEstate.name = i18n.translations[locale].realEstateTypes[realEstate.name];
        return realEstate;
    }), [i18n.translations, locale]);
    const getRealEstate = useCallback(() => {
        const realEstateType = realEstateStoreCtx.catalogFilter.realEstateType;
        const value = realEstateType && realEstates.find((real: RealEstateType) => real.value === realEstateType);
        return value ? value : null;
    }, [realEstateStoreCtx.catalogFilter.realEstateType, realEstates]);
    const [realEstateInitBy, setRealEstateInitBy] = useState<RealEstateType | null>(getRealEstate());
    useEffect(() => {
        const type = getRealEstate();
        setRealEstateInitBy(type ? type : null);
    }, [realEstateStoreCtx.catalogFilter.realEstateType, getRealEstate]);

    useEffect(() => {
        let query: any = {};
        const searchParams = new URLSearchParams(search);
        // @ts-ignore
        for (const [key, value] of searchParams.entries()) {
            query[key] = value;
        }
        realEstateStoreCtx.mergeCatalogFilter(query);
        setBedroomsState({bedroomCountFrom: query.bedroomCountFrom, bedroomCountTo: query.bedroomCountTo});
        setLivingAreaState({livingAreaFrom: query.livingAreaFrom, livingAreaTo: query.livingAreaTo});
        setCatalogPriceState({catalogPriceFrom: query.catalogPriceFrom, catalogPriceTo: query.catalogPriceTo});
        realEstateStoreCtx.loadRealEstates(clone(realEstateStoreCtx.catalogFilter)).then((realEstate) => {});
    }, [search]);

    const handleChangeRealEstateType = (realEstate: RealEstateType) => {
        realEstateStoreCtx.mergeCatalogFilter({realEstateType: realEstate ? realEstate.value : ''});
        navigateToNewState();
    }
    const handleSelectMunicipality = (municipality: Municipality | null) => {
        realEstateStoreCtx.mergeCatalogFilter({municipality: municipality ? municipality.name : ''});
        navigateToNewState();
    }
    const getBedroomsText = () => {
        let text = '';
        const {bedroomCountFrom, bedroomCountTo} = realEstateStoreCtx.catalogFilter;
        if (bedroomCountFrom && bedroomCountTo) {
            text = bedroomCountFrom + ' - ' + bedroomCountTo;
        } else if (bedroomCountFrom && !bedroomCountTo) {
            text = bedroomCountFrom;
        } else if (!bedroomCountFrom && bedroomCountTo) {
            text = bedroomCountTo;
        }
        return text;
    }

    const getLivingAreaText = () => {
        let text = '';
        const {livingAreaFrom, livingAreaTo} = realEstateStoreCtx.catalogFilter;
        if (livingAreaFrom && livingAreaTo) {
            text = livingAreaFrom + ' - ' + livingAreaTo;
        } else if (livingAreaFrom && !livingAreaTo) {
            text = livingAreaFrom;
        } else if (!livingAreaFrom && livingAreaFrom) {
            text = livingAreaTo;
        }
        return text;
    }

    const getPriceText = () => {
        let text = '';
        const {catalogPriceFrom, catalogPriceTo} = realEstateStoreCtx.catalogFilter;
        if (catalogPriceFrom && catalogPriceTo) {
            text = catalogPriceFrom + ' - ' + catalogPriceTo;
        } else if (catalogPriceFrom && !catalogPriceTo) {
            text = catalogPriceFrom;
        } else if (!catalogPriceFrom && catalogPriceTo) {
            text = catalogPriceTo;
        }
        return text;
    }

    const handleChangeFieldNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        switch(event.target.name) {
            case 'bedroomCountFrom':
                setBedroomsState({...bedroomsState, ...{bedroomCountFrom: value}});
                break;
            case 'bedroomCountTo':
                setBedroomsState({...bedroomsState, ...{bedroomCountTo: value}});
                break;
            case 'livingAreaFrom':
                setLivingAreaState({...livingAreaState, ...{livingAreaFrom: value}});
                break;
            case 'livingAreaTo':
                setLivingAreaState({...livingAreaState, ...{livingAreaTo: value}});
                break;
            case 'catalogPriceFrom':
                setCatalogPriceState({...catalogPriceState, ...{catalogPriceFrom: value}});
                break;
            case 'catalogPriceTo':
                setCatalogPriceState({...catalogPriceState, ...{catalogPriceTo: value}});
                break;
            default:
                break;
        }
    }

    const handleReset = (type: string) => {
        switch(type) {
            case 'bedrooms':
                realEstateStoreCtx.mergeCatalogFilter({bedroomCountFrom: '', bedroomCountTo: ''});
                setBedroomsState({bedroomCountFrom: '', bedroomCountTo: ''});
                break;
            case 'livingArea':
                realEstateStoreCtx.mergeCatalogFilter({livingAreaFrom: '', livingAreaTo: ''});
                setLivingAreaState({livingAreaFrom: '', livingAreaTo: ''});
                break;
            case 'price':
                realEstateStoreCtx.mergeCatalogFilter({catalogPriceFrom: '', catalogPriceTo: ''});
                setCatalogPriceState({catalogPriceFrom: '', catalogPriceTo: ''});
                break;
            default:
                break;
        }

        navigateToNewState();
    }

    const handleApply = (type: string) => {
        switch (type) {
            case 'bedrooms':
                const {bedroomCountFrom, bedroomCountTo} = bedroomsState;
                const state = Number(bedroomCountFrom) > Number(bedroomCountTo)
                    ? {...bedroomsState, ...{bedroomCountTo: ''}}
                    : bedroomsState
                realEstateStoreCtx.mergeCatalogFilter(state);
                setBedroomsState(state);
                break;
            case 'livingArea':
                const {livingAreaFrom, livingAreaTo} = livingAreaState;
                const state2 = Number(livingAreaFrom) > Number(livingAreaTo)
                    ? {...livingAreaState, ...{livingAreaTo: ''}}
                    : livingAreaState;
                realEstateStoreCtx.mergeCatalogFilter(state2);
                setLivingAreaState(state2);
                break;
            case 'price':
                const {catalogPriceFrom, catalogPriceTo} = catalogPriceState;
                const state3 =
                    Number(catalogPriceFrom.replace(/./gi, ''))
                    > Number(catalogPriceTo.replace(/./gi, ''))
                    ? {...catalogPriceState, ...{catalogPriceTo: ''}} : catalogPriceState
                realEstateStoreCtx.mergeCatalogFilter(state3);
                setCatalogPriceState(state3);
                break;
            default:
                break;
        }

        navigateToNewState();
    }

    const navigateToNewState = () => {
        navigate(`/buy?` + new URLSearchParams(realEstateStoreCtx.catalogFilter).toString());
    }

    const {bedroomCountFrom, bedroomCountTo} = bedroomsState;
    const {livingAreaFrom, livingAreaTo} = livingAreaState;
    const {catalogPriceFrom, catalogPriceTo} = catalogPriceState;
    const {municipality} = realEstateStoreCtx.catalogFilter;

    return (<div className="catalog-filter">
        <div className="row middle-xs middle-sm middle-md middle-lg middle-xl">
            <div className="col-xs-7 col-sm col-md col-lg real-estate-municipality-combobox">
                <MunicipalitiesCombobox
                    initBy={municipality ? municipality : null}
                    onSelect={handleSelectMunicipality}
                    icon={'icon-location'}
                />
            </div>
            <div className="col-xs-5 col-sm col-md col-lg pr-4 real-estate-type-select">
                <Select
                    prefixTemplate={(item: RealEstateType) => <i className={`${item.iconName}`}/>}
                    items={realEstates}
                    initBy={realEstateInitBy}
                    textKey={'name'}
                    callback={handleChangeRealEstateType}
                    defaultText={i18n.t('catalog.filter.realEstate.placeholder')}
                    nullChoice={true}
                    nullChoiceText={i18n.t('catalog.filter.realEstate.nullChoiceText')}
                />
            </div>
            <div className="col-xs-6 col-sm col-md col-lg real-estate-bedrooms">
                <Popover
                    onOutsideClick={() => handleApply('bedrooms')}
                    onApply={() => handleApply('bedrooms')}
                    onReset={() => handleReset('bedrooms')}
                    content={<div>
                    <div className="row middle-xs middle-sm middle-md middle-lg middle-xl">
                        <div className="col">
                            <FieldRegexp
                                className={`field-regexp field-regexp--extra-small`}
                                name={'bedroomCountFrom'}
                                placeholder={i18n.t('catalog.filter.bedrooms.from')}
                                regexp={'[^0-9]'}
                                type="tel"
                                maxLength={3}
                                value={bedroomCountFrom ? bedroomCountFrom : ''}
                                onChange={handleChangeFieldNumber}
                            />
                        </div>
                        <div className="col">
                            <FieldRegexp
                                name={'bedroomCountTo'}
                                className={`field-regexp field-regexp--extra-small`}
                                placeholder={i18n.t('catalog.filter.bedrooms.to')}
                                regexp={'[^0-9]'}
                                type="tel"
                                maxLength={3}
                                value={bedroomCountTo ? bedroomCountTo : ''}
                                onChange={handleChangeFieldNumber}
                            />
                        </div>
                    </div>
                    <div className="filter-separate"></div>
                    <div className="row middle-lg between-xs">
                        <div className="col-xs-6 col-sm-6 col-md-5 col-lg-6">
                            <Button
                                type="button"
                                data-button-type={'reset'}
                                className="button button--transparent button--extra-small button--content">
                                {i18n.t('catalog.filter.actions.reset')}
                            </Button>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-5 col-lg-6 ta-right">
                            <Button
                                type="button"
                                data-button-type={'apply'}
                                className="button button--extra-small">
                                {i18n.t('catalog.filter.actions.apply')}
                            </Button>
                        </div>
                    </div>
                </div>}>
                    <>
                        {
                            !!(realEstateStoreCtx.catalogFilter.bedroomCountFrom || realEstateStoreCtx.catalogFilter.bedroomCountTo)
                                ? <FieldStatic value={getBedroomsText()} placeholder={i18n.t('catalog.filter.bedrooms.placeholder')} postfix={i18n.t('catalog.filter.bedrooms.placeholder')}/>
                                : <FieldStatic value={getBedroomsText()} placeholder={i18n.t('catalog.filter.bedrooms.placeholder')}/>
                        }
                    </>
                </Popover>
            </div>
            <div className="col-xs-6 col-sm col-md col-lg real-estate-living-area">
                <Popover
                    onOutsideClick={() => handleApply('livingArea')}
                    onApply={() => handleApply('livingArea')}
                    onReset={() => handleReset('livingArea')}
                    content={<div>
                    <div className="row middle-xs middle-sm middle-md middle-lg middle-xl">
                        <div className="col">
                            <FieldRegexp
                                className={`field-regexp field-regexp--extra-small`}
                                name={'livingAreaFrom'}
                                placeholder={i18n.t('catalog.filter.livingArea.from')}
                                regexp={'[^0-9]'}
                                type="tel"
                                maxLength={3}
                                value={livingAreaFrom ? livingAreaFrom : ''}
                                onChange={handleChangeFieldNumber}
                            />
                        </div>
                        <div className="col">
                            <FieldRegexp
                                name={'livingAreaTo'}
                                className={`field-regexp field-regexp--extra-small`}
                                placeholder={i18n.t('catalog.filter.livingArea.to')}
                                regexp={'[^0-9]'}
                                type="tel"
                                maxLength={3}
                                value={livingAreaTo ? livingAreaTo : ''}
                                onChange={handleChangeFieldNumber}
                            />
                        </div>
                    </div>
                    <div className="filter-separate"></div>
                    <div className="row middle-lg between-xs">
                        <div className="col-xs-6 col-sm-6 col-md-5 col-lg-6">
                            <Button
                                type="button"
                                data-button-type={'reset'}
                                className="button button--transparent button--extra-small button--content">
                                {i18n.t('catalog.filter.actions.reset')}
                            </Button>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-5 col-lg-6 ta-right">
                            <Button
                                type="button"
                                data-button-type={'apply'}
                                className="button button--extra-small">
                                {i18n.t('catalog.filter.actions.apply')}
                            </Button>
                        </div>
                    </div>
                </div>}>
                    <FieldStatic value={getLivingAreaText()} placeholder={i18n.t('catalog.filter.livingArea.placeholder')} postfix={'m2'}/>
                </Popover>
            </div>
            <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 real-estate-price">
                <Popover
                    className="popover-price"
                    onOutsideClick={() => handleApply('price')}
                    onApply={() => handleApply('price')}
                    onReset={() => handleReset('price')}
                    content={<div>
                    <div className="row middle-xs middle-sm middle-md middle-lg middle-xl">
                        <div className="col field-with-postfix">
                            <FieldCurrency
                                hideCurrencySymbol={true}
                                className={`field-currency field-currency--extra-small`}
                                name={'catalogPriceFrom'}
                                placeholder={i18n.t('catalog.filter.price.from')}
                                type="text"
                                value={catalogPriceFrom ? catalogPriceFrom : ''}
                                onChange={handleChangeFieldNumber}
                            />
                            <div className="field-postfix">€</div>
                        </div>
                        <div className="col field-with-postfix">
                            <FieldCurrency
                                hideCurrencySymbol={true}
                                name={'catalogPriceTo'}
                                className={`field-currency field-currency--extra-small`}
                                placeholder={i18n.t('catalog.filter.price.to')}
                                type="text"
                                value={catalogPriceTo ? catalogPriceTo : ''}
                                onChange={handleChangeFieldNumber}
                            />
                            <div className="field-postfix">€</div>
                        </div>
                    </div>
                    <div className="filter-separate"></div>
                    <div className="row middle-lg between-xs">
                        <div className="col-xs-6 col-sm-6 col-md-5 col-lg-6">
                            <Button
                                type="button"
                                data-button-type={'reset'}
                                className="button button--transparent button--extra-small button--content"
                                >
                                {i18n.t('catalog.filter.actions.reset')}
                            </Button>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-5 col-lg-6 ta-right">
                            <Button
                                type="button"
                                data-button-type={'apply'}
                                className="button button--extra-small">
                                {i18n.t('catalog.filter.actions.apply')}
                            </Button>
                        </div>
                    </div>
                </div>}>
                    <FieldStatic value={getPriceText()} placeholder={i18n.t('catalog.filter.price.placeholder')} postfix={'€'}/>
                </Popover>
            </div>
        </div>
    </div>)
});

export default Filter;
