import React, {useContext, useRef, useState} from 'react';
import {i18nContext} from '../../../../components/i18n/I18nProvider';
import MunicipalitiesCombobox from '../../../../components/ui/MunicipalitiesCombobox/MunicipalitiesCombobox';
import {Municipality} from '../../../../store/components/RealEstateStore';
import './Searcher2.scss';
import Popover from '../../../../components/ui/Popover/Popover';
import {useNavigate, useOutletContext} from 'react-router-dom';
import {clientsStoreContext} from '../../../../store/components/ClientStore';

type Searcher2Props = {}

const Searcher2: React.FC<Searcher2Props> = (props) => {
    const navigate = useNavigate();
    const {setClientModalProps} = useOutletContext<any>();
    const clientStoreCtx = useContext(clientsStoreContext);
    const [mode, setMode] = useState<string>('buy');
    // const [region, setRegion] = useState<any>('Valencia');
    const [municipality, setMunicipality] = useState<Municipality | null>(null);
    const popoverRef = useRef<HTMLDivElement>(null);
    const handleSelectRegion = (city: any) => {
        // @ts-ignore
        setTimeout(() => popoverRef.current && popoverRef.current.close(), 50);
    }
    const handleSelectMunicipality = (municipality: Municipality | null) => setMunicipality(municipality);
    const handleNavigateToCatalog = () => {
        if (municipality) {
            navigate(`/buy?realEstateType=&municipality=${municipality.name}&bedroomCountFrom=&bedroomCountTo=&livingAreaFrom=&livingAreaTo=&catalogPriceFrom=&catalogPriceTo=`);
        } else {
            navigate(`/buy?realEstateType=&municipality=&bedroomCountFrom=&bedroomCountTo=&livingAreaFrom=&livingAreaTo=&catalogPriceFrom=&catalogPriceTo=`);
        }
    }

    const handleOpenSurvey = () => {
        if (municipality) {
            clientStoreCtx.mergeClient({municipality: municipality.name});
        }
        setClientModalProps({isOpen: true});
    }

    const handleApply = () => {
        if (mode === 'buy') {
            handleNavigateToCatalog();
        } else {
            handleOpenSurvey();
        }
    }

    return (<div className="searcher">
        {/*<div className="searcher__mode-container">*/}
        {/*    <div className={`searcher__mode-item ${mode === 'buy' ? 'active' : ''}`} onClick={() => setMode('buy')}>*/}
        {/*        {i18n.t('buy.searcher.buy')}*/}
        {/*    </div>*/}
        {/*    <div className={`searcher__mode-item ${mode === 'sell' ? 'active' : ''}`} onClick={() => setMode('sell')}>*/}
        {/*        {i18n.t('buy.searcher.sell')}*/}
        {/*    </div>*/}
        {/*</div>*/}
        <div className="searcher__field">
            <Popover
                className="field-city-dropdown-wrapper"
                ref={popoverRef}
                content={<div className="searcher__field-city-dropdown">
                    <button
                        className="searcher__field-city-dropdown-item"
                        onClick={() => handleSelectRegion('Valencia')}>
                        <div>
                            <i className="icon-building1" />
                            Valencia
                        </div>
                    </button>
                    <button
                        className="searcher__field-city-dropdown-item"
                        disabled={true}
                        onClick={() => handleSelectRegion('Barcelona')}>
                        <div>
                            <i className="icon-building1" />
                            Barcelona
                        </div>
                        <div className="searcher__field-city-dropdown-item--coming">coming soon</div>
                    </button>
                </div>}>
                <div className="field-city-dropdown__trigger">
                    <div className="searcher__field-city">
                        <i className="icon-building1" />
                        <span>Valencia</span>
                        <i className="icon-arrow" />
                    </div>
                </div>
            </Popover>
            <div className="searcher__field-input">
                <MunicipalitiesCombobox onSelect={handleSelectMunicipality} mode={'clear'}/>
                <div className="searcher__field-button" onClick={handleApply}>
                    <i className="icon-search1"/>
                </div>
            </div>
        </div>
    </div>)
}

export default Searcher2;
