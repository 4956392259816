import React, {useEffect, useMemo, useState} from 'react';
import './Select.scss';
import {useFormContext} from 'react-hook-form';

type SelectProps = {
    items: any[],
    initBy: any;
    textKey: string;
    defaultText?: string;
    nullChoice?: boolean;
    nullChoiceText?: string;
    isDisabled?: boolean;
    callback: (selectedValue: any) => void;
    prefixTemplate?: (item: any) => JSX.Element;
    registerProps?: any;
    className?: any;
    onOpen?: () => void;
}

const Select: React.FC<SelectProps> =({registerProps, className, ...props}) => {
    const methods = useFormContext();
    const setValue = methods && methods.setValue;
    const trigger = methods && methods.trigger;
    const registerPropsName = registerProps && registerProps.name;
    const [isOpen, setOpen] = useState(false);
    const [initBy, setInitBy] = useState(props.initBy);
    const defaultText = useMemo(() => props.defaultText || 'Select value', [props.defaultText]);
    const textKey = useMemo(() => props.textKey, [props.textKey]);
    const [isDirty, setDirty] = useState(false);

    useEffect(() => {
        if (isDirty && setValue && trigger && registerPropsName) {
            setValue && setValue(registerPropsName, props.initBy ? props.initBy[textKey] : '');
            trigger && trigger(registerPropsName).then(() => {});
        }
        if (props.initBy) {
            setInitBy(props.initBy);
            setValue && setValue(registerPropsName, props.initBy ? props.initBy[textKey] : '');
            trigger && trigger(registerPropsName).then(() => {});
        }
    }, [setValue, trigger, registerPropsName, props.initBy, textKey, isDirty]);

    const onToggleDropdown = () => {
        if (props.isDisabled) {
            return;
        }
        setOpen(!isOpen);
        !isOpen && props.onOpen && props.onOpen();
    }

    const onBlur = (event: any) => {
        if (!event.target.parentElement.contains(event.relatedTarget)) {
            setOpen(false);
        }
    }

    const onReset = () => {
        setInitBy(null);
        setOpen(false);
        registerProps && setValue(registerProps.name, '');
        props.callback && props.callback(null);
        setDirty(true);
    }

    const onSelect = (selectedValue: any) => {
        setInitBy(selectedValue);
        setOpen(false);
        registerProps && setValue(registerProps.name, selectedValue[textKey]);
        registerProps && trigger(registerProps.name);
        props.callback && props.callback(selectedValue);
        setDirty(true);
    }

    const renderPrefixTemplate = (item: any) => {
        return props.prefixTemplate ? <span className={'select__prefix-template'}>{props.prefixTemplate(item)}</span> : '';
    }

    return (<div className={`${className ? className : 'select'}`}>
        {registerProps && methods && <input type="hidden" {...methods.register(registerProps.name, registerProps.options)} />}
        <button
            className={`select__trigger ${registerProps && methods && methods.formState.errors && methods.formState.errors[registerProps.name] ? 'select__trigger-invalid' : ''}`}
            type="button"
            disabled={props.isDisabled}
            onClick={onToggleDropdown}
            onBlur={onBlur}
        >
            {initBy && renderPrefixTemplate(initBy)}
            {initBy
                ? <span className="select__trigger--text">{initBy[textKey]}</span>
                : <span className="select__trigger--placeholder">{defaultText}</span>}
            <i className="icon-arrow-down" />
        </button>
        {isOpen && <div className="select__dropdown">
            <div>
                {props.nullChoice &&
                <button
                    type="button"
                    onClick={onReset}
                    className="select__dropdown-item select__dropdown-item--placeholder">
                    {props.nullChoiceText || defaultText}
                </button>}
                {props.items.map((item: any, index: number) => (
                    <button
                        type="button"
                        onClick={() => onSelect(item)}
                        className="select__dropdown-item"
                        key={item[textKey] + '_' + index}>
                        {props.prefixTemplate && renderPrefixTemplate(item)}
                        {item[textKey]}
                    </button>))}
            </div>
        </div>}
    </div>);
}

export default Select;
