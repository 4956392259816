import React, {useContext} from 'react';
import './ClientSuccessModal.scss';
import Modal from '../../../../components/ui/Modal/Modal';
import Button from '../../../../components/ui/Button/Button';
import {i18nContext} from '../../../../components/i18n/I18nProvider';

type PrivacyModalProps = {
    onClose: () => void;
}

const ClientSuccessModal: React.FC<PrivacyModalProps> = (props) => {
    const {i18n} = useContext(i18nContext);
    const handleCloseModal = () => {
        props.onClose();
        document.body.classList.remove('ReactModal__Body--open');
    }

    return (<Modal
        className={'ReactModal__Content-success'}
        isOpen={true}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
    >
        <div className="modal-header">
            <div className="row between-xs">
                <div className="col-xs-2 col-sm-1 col-md-1 col-lg-1">

                </div>
                <div className="col-xs-8 col-sm-10 col-md-10 col-lg-10 ta-center">
                    {i18n.t('clientSuccessModal.header.title')}
                </div>
                <div className="col-xs-2 col-sm-1 col-md-1 col-lg-1 ta-right">
                    <i className="icon-close" onClick={handleCloseModal}/>
                </div>
            </div>
        </div>
        <div className="modal-content">
            <div className="row middle-sm middle-md middle-lg middle-xs middle-xl">
                <div className="col col-success col-heart">
                    <i className="icon-heart"/>
                </div>
            </div>
            <div className="row middle-sm middle-md middle-lg middle-xs middle-xl">
                <div className="col col-success col-title">
                    {i18n.t('clientSuccessModal.title')}
                </div>
            </div>
            <div className="row middle-sm middle-md middle-lg middle-xs middle-xl">
                <div className="col col-success col-description">
                    {i18n.t('clientSuccessModal.description')}
                </div>
            </div>
        </div>
        <div className="modal-actions">
            <div className="row middle-xs middle-sm middle-md middle-lg middle-xl center-xl center-lg center-xs center-sm center-md">
                <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                    <Button onClick={handleCloseModal}>
                        {i18n.t('clientSuccessModal.button')}
                    </Button>
                </div>
            </div>
        </div>
    </Modal>)
}

export default ClientSuccessModal;
