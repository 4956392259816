import React, {useEffect, useState} from 'react';
import './FieldCounter.scss';
import {useFormContext} from 'react-hook-form';

const FieldCounter: React.FC<{registerProps?: any, count: number; onChange: (count: number) => void}> =({registerProps, ...props}) => {
    const methods = useFormContext();
    const [count, setCount] = useState(0);
    useEffect(() => {
        setCount(+props.count);
    }, [props.count]);
    useEffect(() => {
        if (+props.count) {
            setValueAndValidate(+props.count);
        }
    }, []);
    const handleDecrement = () => {
        const value = +count - 1;
        setValueAndValidate(value);
        if (value !== -1) {
            props.onChange(value);
            return;
        }
    }
    const handleIncrement = () => {
        const value = +count + 1;
        props.onChange(value);
        setValueAndValidate(value);
    }

    const setValueAndValidate = (value: number) => {
        if (value <= 0 && methods && registerProps) {
            methods.setValue(registerProps.name, '');
            methods.trigger(registerProps.name);
        } else if (value > 0 && methods && registerProps) {
            methods.setValue(registerProps.name, value);
            methods.trigger(registerProps.name);
        }
    }
    return (<div className={`field-counter ${registerProps && methods && methods.formState && methods.formState.errors[registerProps.name] ? 'field-counter-invalid' : ''}`}>
        {registerProps && methods && <input type="hidden" {...methods.register(registerProps.name, registerProps.options)}/>}
        <i className="icon-circle-minus" onClick={handleDecrement}/>
        <div className="field-counter-text">{count}</div>
        <i className="icon-circle-plus" onClick={handleIncrement}/>
    </div>);
}

export default FieldCounter;
